<template>
  <div>
    <div class="bigDiv">
      <div>
        <div v-html="text" class="text" id=img>

        </div>
        <el-dialog :visible.sync="dialogTableVisible">
          <div class="imgs">
            <img :src="imgUrl" alt="">
          </div>
          <div class="tips">长按图片保存/发给指导师</div>
        </el-dialog>

      </div>
      <div class="btnFixd" @click="getImage">
        <img src="../../assets/images/shiliao/btn.gif" alt="">
      </div>
      <!-- <div @touchstart="handleTouchStart"
    @touchmove="handleTouchMove">我是盒子</div> -->
    </div>
  </div>
</template>

<script>

import { toPng } from 'html-to-image'
import { getWechatConfig } from "@/api/yangsheng";

export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      dialogTableVisible: false,
      imgUrl: '',
      xz: '',
      yz: '',
      items: 0,
      text: '',
    }
  },
  methods: {


    getImage () {
      const node = document.querySelector('#img')
      toPng(node).then((dataUrl) => {
        // 使用图片数据URL
        this.imgUrl = dataUrl
        this.dialogTableVisible = true
      })
        .catch(function (error) {
          console.error('转换错误:', error);
        });
    },

    getWechatConfig () {

      const query = {
        company_id: 1,
        url: window.location.href
      }
      getWechatConfig(query).then(res => {
        const agent = res.data.data
        if (res.data.code === 200) {
          this.$nextTick(() => {

          })
          wx.config({
            debug: agent.debug, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: agent.appId, // 必填，公众号的唯一标识
            timestamp: agent.timestamp, // 必填，生成签名的时间戳
            nonceStr: agent.nonceStr, // 必填，生成签名的随机串
            signature: agent.signature,// 必填，签名
            //1.4.0的分享接口,注意：使用新版本的分享功能，一定要在该列表加上对应的老版本功能接口，否则新接口不起作用
            //     "onMenuShareAppMessage", //老版本分享接口。
            //     "onMenuShareTimeline" //老版本分享接口。
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData,'onMenuShareAppMessage", "onMenuShareTimeline"] // 必填，需要使用的JS接口列表
          });

          this.share()
        }

      }
      )
    },
    share () {
      this.$nextTick(() => {
        wx.ready(function () {
          // 分享给朋友
          wx.updateAppMessageShareData({
            title: '血管年龄查询', // 分享标题
            link: 'https://kf.hshwhkj.com/shiliaoA', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '三道题测出血管的真实年龄', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          });
          // 分享到朋友圈
          wx.updateTimelineShareData({
            title: '血管年龄查询', // 分享标题
            link: 'https://kf.hshwhkj.com/shiliaoA', //这里可带参数，必须是同配置的JS安全域名一致
            desc: '三道题测出血管的真实年龄', // 分享描述
            imgUrl: 'https://oss.hshwhkj.com/hshcrm/image/2024/07/09/172049606735669199.png',// 必须是同配置的JS安全域名一致
            success: function () { }
          })

        });
        wx.error(res => {
          console.log('微信分享错误信息：', res)
        })
      })



    },
  },
  created () {
    this.text = sessionStorage.getItem('results')
  },
  mounted () {
    this.getWechatConfig()
  },
  watch: {

  },
  computed: {

  },
  filters: {

  }
}
</script>

<style scoped lang='less'>
* {
  overflow: hidden;
  background-color: #f9f9f9;
  margin: 0;
}
.text {
  overflow: hidden;
  padding: 10px;
  font-size: 15px;
  color: #333;
  line-height: 2rem;
}
.btnFixd {
  width: 100%;
  margin-top: 120px;
  img {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
}
@media screen and (min-width: 750px) {
  .bigDiv {
    width: 500px;
    margin: 0 auto;
    .text {
      overflow: hidden;
      padding: 20px;
      font-size: 20px;
      line-height: 40px;
    }
    .btnFixd {
      width: 500px;
      margin-top: 120px;
      img {
        height: 120px;
        position: fixed;
        object-fit: convert;
        bottom: 0;
        width: 500px;
      }
    }
  }
}
</style>
<style  lang='less'>
@media screen and (min-width: 750px) {
  .el-dialog {
    width: 500px;
    height: 600px;
    border-radius: 20px;
    overflow: auto;
    position: relative;
    top: 0;
    .imgs {
      width: 100%;

      img {
        width: 100%;
      }
    }
    .tips {
      position: fixed;
      bottom: 140px;
      left: 50%;
      transform: translate(-50%);
      background-color: rgba(0, 0, 0, 0);
      color: #f9f9f9;
      font-size: 20px;
    }
    .el-dialog__wrapper {
      background-color: rgba(0, 0, 0, 0.5);
      top: -100px;
    }
  }
}
@media screen and (max-width: 750px) {
  .el-dialog {
    width: 95%;
    height: 450px;
    border-radius: 20px;
    overflow: auto;
    position: relative;
    .imgs {
      width: 100%;

      img {
        width: 100%;
      }
    }
    .tips {
      position: fixed;
      bottom: 60px;
      left: 20%;
      background-color: rgba(0, 0, 0, 0);
      color: #f9f9f9;
      font-size: 20px;
    }
  }
  .el-dialog__wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
  }
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__close {
    margin-top: -20px;
  }
}
</style>